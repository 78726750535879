import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { missingPopup } from "../../helpers/dataHelper/missingData";
// import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../helpers/apiHelper/requestHelper";
import { Popover, OverlayTrigger, Form } from "react-bootstrap";
import validator from "validator";
import Button from "../../components/button/Button";
import {
    setLoading,
    setSettingUpService,
    setSigningYouIn,
} from "../../redux/loading/loadingSlice";
import LoadingModal from "../../components/loader/loadingModal";
import { useAnimate, usePresence, stagger } from "framer-motion";
import InputComponent from "../../components/input/InputComponent";
import { storeUser } from "../../redux/users/usersSlice";
import Footer from "../../components/footer/Footer";
import { setCurrentTab } from "../../redux/links/linksSlice";
import { setShowSidebar } from "../../redux/sidebar/sidebarSlice";
import { userCommunity } from "../../redux/community/communitySlice";

const handleClick = () => {
    window.scrollTo(0, 0);
};

export default function SignupMember({ type }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useApi();
    const { id } = useParams();
    const isLoading = useSelector((state) => state?.loader?.isLoading);
    const responseLoading = useSelector(
        (state) => state?.loader?.awaitingResponse
    );
    const user = useSelector((state) => state?.user?.user);
    const { state } = useLocation();
    console.log(state, "LocationState")
    const [data, setData] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        role: "member",
        termsConditions: false,
        acceptMarketing: false,
    });

    const [validationMessage, setValidationMessage] = useState("");
    const [focusedInput, setFocusedInput] = useState(null);
    const [verify, setVerify] = useState(false);
    const [isPresent, safeToRemove] = usePresence();
    const [scope, animate] = useAnimate();
    const [otp, setOtp] = useState("");
    const [verifyData, setVerifyData] = useState({});
    const [codeIncorrect, setCodeIncorrect] = useState(false);
    const [showTermsMessage, setShowtermsMessage] = useState(false)
    const numberRegex = new RegExp(`^[0-9]*$`);

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput(null);
    };

    const handlePasswordChange = (value) => {
        const newPassword = value;
        if (!newPassword) {
            setValidationMessage("");
        } else if (!/[A-Z]/.test(newPassword)) {
            setValidationMessage(
                "Password must contain at least one uppercase letter"
            );
        } else if (!validator.isLength(newPassword, { min: 8 })) {
            setValidationMessage("Password must be at least 8 characters long");
        } else if (!/[0-9]/.test(newPassword)) {
            setValidationMessage("Password must contain at least one digit");
        } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(newPassword)) {
            setValidationMessage(
                "Password must contain at least one special character"
            );
        } else {
            setValidationMessage(""); // Clear validation message if all rules are satisfied
        }

        setData((prevState) => ({
            ...prevState,
            password: newPassword,
        }));
    };

    useEffect(() => {
        if (id && type === "subscription") {
            setData((prev) => ({
                ...prev,
                referralCode: id,
                source: type,
            }));
        }
    }, [id]);

    useEffect(() => {
        if (user) {
            if (user.role === "leader") navigate("/leader/dashboard", { replace: true });
            else {
            } navigate("/member/dashboard", { replace: true });

        }
    }, [window.location, user]);

    useEffect(() => {
        if (user?.role === 'member' && user?.firstVisit) {
            navigate('/member/copyTrade', { replace: true });
        }
    }, [navigate]);

    const handleSignup = async () => {
        dispatch(setLoading(true));
        if (!state) {
            dispatch(setLoading(false));
            toastify("Community is missing");
            return;
        }
        if (validationMessage !== "") {
            dispatch(setLoading(false));
            toastify("Please enter a valid password");
            return;
        }
        if (!data?.email) {
            dispatch(setLoading(false));
            missingPopup("Email");
        } else if (!/\S+@\S+\.\S+/.test(data?.email)) {
            dispatch(setLoading(false));
            toastify("Please enter a valid email address.");
        } else if (!data?.password) {
            dispatch(setLoading(false));
            missingPopup("Password", "error");
        } else if (!data?.confirmPassword) {
            dispatch(setLoading(false));
            missingPopup("Confirm Password");
        } else if (data?.password !== data?.confirmPassword) {
            dispatch(setLoading(false));
            toastify("Passwords do not match", "error");
        } else if (!data.termsConditions) {
            dispatch(setLoading(false));
            return setShowtermsMessage(true)
        }
        else {
            const response = await api("post", "auth/signup", {
                ...data,
                ref: state ? state : null,
            });
            // alert(response?.data?.otp);
            // console.log(response?.data?.otp);
            if (response?.status) {
                dispatch(setLoading(false));
                localStorage.removeItem("tourClose");
                setVerify(true);
                setVerifyData({ email: data?.email });
                // navigate("/login/verifyEmail", {
                //     state: { email: data?.email },
                // });
            } else {
                dispatch(setLoading(false));
                toastify(response?.message);
            }
        }
    };

    const handleResendCode = async () => {
        const response = await api("post", "auth/login", data);

        if (response?.status) {
            toastify(response?.message, "success");
            console.log("handle resend", response);
        }
    };

    const handleVerify = async () => {
        if (!otp || otp.length < 6) {
            // toastify("Please enter a 6 digit Code sent to your email address.");
            setCodeIncorrect(true);
        } else {
            dispatch(setSettingUpService(true));
            const response = await api("post", "auth/verifyEmailOtp", {
                email: verifyData?.email,
                otp,
            });
            if (response?.status) {
                setTimeout(() => {
                    dispatch(setSettingUpService(false));
                }, 4000);
                // toastify("Email verified successfully.", "success");

                // dispatch(setLoading(false));
                dispatch(storeUser(response?.data));
                localStorage.setItem("token", response?.data?.token);
                await api("post", "users/update", {
                    security: { otp: false },
                });
                if (response?.data?.user?.role === "member") {
                    const lastVisitedRoute =
                        localStorage.getItem("lastVisitedRoute");
                    setTimeout(() => {
                        dispatch(setSigningYouIn(false));
                    }, 4000);
                    if (
                        lastVisitedRoute &&
                        lastVisitedRoute?.includes("member")
                    ) {
                        localStorage.removeItem("lastVisitedRoute");
                        navigate(lastVisitedRoute);
                    } else {
                        navigate("/member/copyTrade", { replace: true });
                        dispatch(setShowSidebar(false))
                        dispatch(setCurrentTab("connection"))
                        dispatch(userCommunity(null))
                    }
                } else {
                    const lastVisitedRoute =
                        localStorage.getItem("lastVisitedRoute");
                    if (
                        lastVisitedRoute &&
                        lastVisitedRoute?.includes("leader")
                    ) {
                        localStorage.removeItem("lastVisitedRoute");
                        navigate(lastVisitedRoute);
                    } else {
                        navigate("/leader/dashboard", { replace: true });
                    }
                }
            } else {
                setTimeout(() => {
                    dispatch(setSettingUpService(false));
                }, 1000);
                toastify(response?.message);
            }
        }
    };

    useEffect(() => {
        const enterAnimation = async () => {
            await animate(
                scope.current,
                {
                    x: verify ? [300, 0] : [-300, 0], // Updated for verify state
                    // opacity: [0, 1],
                    delay: stagger(0.1, { ease: "easeOut" }),
                },
                { duration: 0.2 }
            );
        };

        const exitAnimation = async () => {
            await animate(
                scope.current,
                {
                    x: verify ? [0, -300] : [0, 300], // Updated for verify state
                    // opacity: [1, 0],
                    delay: stagger(0.1, { ease: "easeOut" }),
                },
                { duration: 0.2 }
            );
            safeToRemove();
        };

        if (isPresent) {
            enterAnimation();
        } else {
            exitAnimation();
        }
    }, [scope, isPresent, verify]);


    useEffect(() => {
        if (otp?.length === 6) {
            handleVerify();
        }
    }, [otp]);

    console.log(state, "LocationStateLast")


    return (
        <div className="common_style wrapper">
            <Header />
            {isLoading && <LoadingModal />}

            <section className="sign_section d-flex align-items-center">
                {verify ? (
                    <div className="login_card verify_card_name account_card position-relative">
                        <div className="container">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-sm-12 px-0">
                                    <div className="d-flex justify-content-between flex-column">
                                        {state?.name && (
                                            <p className="fs16 bold mb-21 w-100 start-0 text-center set-postion-tamname">
                                                {state?.name?.toUpperCase()}
                                            </p>
                                        )}
                                        <div>
                                            <h2 className="fs20 li_h30 text_GB mb-9">
                                                Check your email{" "}
                                            </h2>
                                            <h5 className="text-center fs14 mb-26 regular">
                                                Use the code sent to your email
                                            </h5>

                                            {/* <p className="mb-3 ps-2">
                                 Please check your spam folder too
                             </p> */}
                                            <Form.Group
                                                ref={scope}
                                                controlId="formOtp"
                                            >
                                                {/* <Form.Label>Enter the code here</Form.Label> */}
                                                <InputComponent
                                                    type="text" // or "password" based on your requirement
                                                    label="Paste Code here"
                                                    focusedInput={
                                                        otp ? "focused" : ""
                                                    }
                                                    handleFocus={handleFocus}
                                                    value={otp}
                                                    maxLength={6}
                                                    onChange={(inputValue) => {
                                                        if (
                                                            numberRegex.test(
                                                                inputValue
                                                            ) ||
                                                            inputValue === ""
                                                        ) {
                                                            setOtp(inputValue);
                                                        }
                                                    }}
                                                />
                                                {codeIncorrect && (
                                                    <p className="text-danger fs15">
                                                        Code incorrect
                                                    </p>
                                                )}
                                            </Form.Group>
                                        </div>
                                        {/* <div className="h-85 d-flex align-items-end justify-content-between">
                                            <Link
                                                className="cyan-color bold"
                                                style={{ cursor: "pointer" }}
                                                onClick={handleResendCode}
                                            >
                                                Resend code
                                            </Link>
                                            <button
                                                className="cyan-btn nextSign text_GM"
                                                onClick={handleVerify}
                                            >
                                                Next
                                            </button>
                                        </div> */}
                                        <div className="">
                                            <div className="mt-19">
                                                <button className="newCreateButton" onClick={handleVerify}>Verify email</button>
                                            </div>
                                            <div className="mt-12 text-center">
                                                <Link
                                                    className="newSignLink"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={handleResendCode}
                                                >
                                                    Resend code
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="login_card account_card position-relative">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-sm-12 px-0">
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        {state?.name && (
                                            <p className="fs16 bold mb-21 text-center set-postion-tamname">
                                                {state?.name?.toUpperCase()}
                                            </p>
                                        )}
                                        <h2 className="fs20 bold mb-9">
                                            Create an account
                                        </h2>
                                        <h6 className="fs14 mb-26 regular text-center">
                                            Submit email and create a strong
                                            password
                                        </h6>
                                    </div>
                                    <div ref={scope}> </div>
                                    <div className="pb-13">
                                        <InputComponent
                                            type="email"
                                            label="Email"
                                            focusedInput={focusedInput}
                                            handleFocus={handleFocus}
                                            handleBlur={handleBlur}
                                            value={data.email}
                                            onChange={(value) =>
                                                setData((prevState) => ({
                                                    ...prevState,
                                                    email: value,
                                                }))
                                            }
                                        />
                                    </div>
                                    <div className="pb-13">
                                        <InputComponent
                                            type="password"
                                            label="Password"
                                            focusedInput={focusedInput}
                                            handleFocus={handleFocus}
                                            handleBlur={handleBlur}
                                            value={data?.password}
                                            onChange={(val) =>
                                                handlePasswordChange(val)
                                            }
                                        />
                                        {validationMessage && (
                                            <div className="text-danger">
                                                {validationMessage}
                                            </div>
                                        )}
                                    </div>
                                    <div className="pb-13">
                                        <InputComponent
                                            type="password"
                                            label="Verify Password"
                                            focusedInput={focusedInput}
                                            handleFocus={handleFocus}
                                            handleBlur={handleBlur}
                                            value={data?.confirmPassword}
                                            onChange={(value) =>
                                                setData((prevState) => ({
                                                    ...prevState,
                                                    confirmPassword: value,
                                                }))
                                            }
                                        />
                                    </div>

                                    <div className="pb-13">
                                        <div className="d-flex">
                                            <div className="me-2">
                                                <input
                                                    id="term1Checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        data?.termsConditions
                                                    }
                                                    onChange={(e) =>
                                                        setData(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                termsConditions:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="fs12 text_muted5 mt-1">
                                                <p>
                                                    I accept the Terms &
                                                    Conditions and have read the
                                                    Privacy Policy and allow
                                                    copyyy.it and{" "}
                                                    {state?.name} to contact me
                                                    by email regarding this
                                                    service.
                                                </p>
                                                {showTermsMessage &&
                                                    (
                                                        <p className="text-danger fs16 mt-3">
                                                            Please accept so we
                                                            can send you emails
                                                            regarding this
                                                            service.{" "}
                                                        </p>
                                                    )}
                                            </div>
                                        </div>

                                        {/* <div className="d-flex py-2">
                                                <div className="me-2">
                                                    <input
                                                        id="cryptoCheckbox"
                                                        type="checkbox"
                                                        checked={data?.acceptMarketing}
                                                        onChange={(e) =>
                                                            setData((prevState) => ({
                                                                ...prevState,
                                                                acceptMarketing:
                                                                    e.target.checked,
                                                            }))
                                                        }
                                                    />
                                                </div>
                                                <div className="fs12 text_muted5 mt-1">
                                                    I’m ok with getting occasional
                                                    marketing emails with promotional
                                                    offers related to my trading.
                                                </div>
                                            </div> */}
                                        {/* <div className="mt-3 d-flex align-items-end justify-content-between">
                                            <div
                                                className="cursor-pointer cyan-color bold signup-text"
                                                onClick={() =>
                                                    navigate("/login", {
                                                        state: {
                                                            community: state,
                                                        },
                                                    })
                                                }
                                            >
                                                Sign in instead
                                            </div>

                                            <button
                                                className="cyan-btn nextSign text_GM"
                                                disabled={
                                                    !data?.termsConditions
                                                }
                                                onClick={handleSignup}
                                            >
                                                Next
                                            </button>
                                        </div> */}
                                        <div className="mt-25">
                                            <button className="newCreateButton"
                                                // disabled={
                                                //     !data?.termsConditions
                                                // }
                                                onClick={() => { handleClick(); handleSignup(); }}>Create account</button>
                                            <div className="mt-12">
                                                <a onClick={() =>
                                                    navigate("/login")
                                                } className="newSignLink">Sign in instead</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </section>

            <Footer />
        </div>
    );
}
