import React, { useState, useEffect } from "react";
import Copy from "../../../assets/images/Copy.svg";
import Delete from "../../../assets/images/Delete.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loading/loadingSlice";
import RenderLinks from "../../../components/links/RenderLinks";

function CreatePromotion() {
    const [discounts, setDiscounts] = useState([]);
    const [selectedCode, setSelectedCode] = useState();
    const [code, setCode] = useState("");
    const [percentage, setPercentage] = useState("");
    const user = useSelector((state) => state.user.user);
    const api = useApi();
    const dispatch = useDispatch();

    const community = user?.leaderCommunity;

    const getDiscounts = async () => {
        const response = await api("get", "discount/index");
        if (response?.status) {
            setDiscounts(response?.data);
        } else {
            toastify(response?.message);
        }
    };

    useEffect(() => {
        getDiscounts();
    }, []);

    const handlePercentageChange = (e) => {
        const value = e.target.value;
        if (value === "" || (Number(value) <= 100 && Number(value) >= 0)) {
            setPercentage(value);
        }
    };

    const handleCreateDiscount = async () => {
        if (code?.length < 6) {
            toastify("Promo code should be (6-8 characters)")
            return
        }
        dispatch(setLoading(true));

        // Validate that both code and percentage are provided before making the API call
        if (!code || !percentage) {
            toastify(
                "Please provide both code and percentage for the discount",
                "error"
            );
            dispatch(setLoading(false));
            return;
        }

        try {
            const response = await api("post", "discount/create", {
                code,
                percentage,
                community,
            });

            console.log(response);

            if (response?.status) {
                toastify("Discount code created successfully", "success");
                setDiscounts(response?.data);
                setCode("");
                setPercentage("");
            } else {
                toastify(response?.message, "error");
            }
        } catch (error) {
            console.error("Error creating discount:", error);
            toastify("An error occurred while creating the discount", "error");
        }

        dispatch(setLoading(false));
    };

    const handleDeleteDiscount = async (id) => {
        dispatch(setLoading(true));
        const response = await api("post", "discount/deleteDiscount", {
            id,
        });
        console.log(response);
        if (response?.status) {
            toastify(response?.message, "success");
            getDiscounts();
        } else {
            toastify(response?.message);
        }

        dispatch(setLoading(false));
    };

    return (
        <React.Fragment>
            <div className="connection autoshareJs p-top mx-691 mt-sm-30">
                <div className="title">
                    <h3>Create your discount codes</h3>
                    <p>
                        You can create as many as you want. Each code will apply
                        to the first month of a membership for a new
                        member when signing up.
                    </p>
                </div>
                <div className="discount">
                    <div className="discountInputs">
                        {/* <div className="messageInput">
                            <div className="form-floating">
                                <input
                                    className="form-control"
                                    id="discount"
                                    type="text"
                                    placeholder="Discount (%)"
                                    value={percentage}
                                    onChange={(e) =>
                                        setPercentage(e.target.value)
                                    }
                                />
                                <label htmlFor="discount">Discount (%)</label>
                            </div>
                        </div> */}
                        <div className=" pb-4 messageInputs">
                            <div className="customInput">
                                <div className="form-floating">
                                    <input
                                        id="discount"
                                        // ref={nameRef}
                                        type="text"
                                        className="form-control fs15"
                                        placeholder="Discount (%)"
                                        value={percentage}
                                        onChange={handlePercentageChange}
                                        maxLength={percentage && parseFloat(percentage) < 10 ? 4 : 5}
                                    />
                                    <label for="discount">
                                        Discount (%)
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* <div className="messageInput">
                            <div className="form-floating">
                                <input
                                    className="form-control"
                                    id="promotion"
                                    type="text"
                                    placeholder="Promotion code (8 characters)"
                                    value={code}
                                    onChange={(e) => {
                                        if (
                                            /^[a-zA-Z0-9]+$/.test(
                                                e.target.value
                                            ) ||
                                            e.target.value === ""
                                        ) {
                                            setCode(e.target.value);
                                        }
                                    }}
                                    maxLength={8}
                                />
                                <label htmlFor="promotion">
                                    Promotion code (8 characters)
                                </label>
                            </div>
                        </div> */}
                        <div className=" pb-4 messageInputs">
                            <div className="customInput">
                                <div className="form-floating">
                                    <input
                                        id="promotion"
                                        type="text"
                                        className="form-control fs15"
                                        placeholder="Promotion code (6-8 characters)"
                                        value={code}
                                        onChange={(e) => {
                                            const upperCaseValue = e.target.value.toUpperCase();
                                            if (/^[A-Z0-9]*$/.test(upperCaseValue) || upperCaseValue === "") {
                                                setCode(upperCaseValue);
                                            }
                                        }}
                                        maxLength={8}
                                        minLength={6}
                                    />

                                    <label for="discount">
                                        Promotion code (6-8 characters)
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="discountBtn discountInputs">
                        <button
                            className="black-btn text_GM"
                            onClick={handleCreateDiscount}
                        >
                            Create
                        </button>
                    </div>
                </div>
                {discounts?.length > 0 && (
                    <div className="discount mx-407">
                        <div className="title">
                            <p>Discounts</p>
                        </div>
                        {discounts?.map((d) => (
                            <div className="card">
                                <div className="block">
                                    <h3>{d?.percentage}%</h3>
                                </div>
                                <div className="block">
                                    <h3>{d?.code}</h3>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img
                                            src={Copy}
                                            alt="copy"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    `${d?.code}`
                                                );
                                                toastify(
                                                    "Discount code copied to clipboard!",
                                                    "success"
                                                );
                                            }}
                                        />
                                        <img
                                            src={Delete}
                                            alt="Delete"
                                            onClick={() =>
                                                handleDeleteDiscount(d?._id)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <RenderLinks page="performancePromotion" />
            </div>
        </React.Fragment>
    );
}

export default CreatePromotion;
