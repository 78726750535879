import React, { useEffect, useState } from "react";
import Pagination from 'react-bootstrap/Pagination';
import Prev from "../../src/assets/images/pagination-left.svg"
import Next from "../../src/assets/images/pagination-right.svg"

export default function CustomPagination({ page, setPage, items, filtered }) {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (filtered?.length > 0) {
      setPages(
        Array.from(
          { length: roundUpToLimit(filtered?.length, 10) / 10 || 1 },
          (_, index) => index + 1 // Start from 1
        )
      );
    } else {
      setPages(
        Array.from(
          { length: roundUpToLimit(items?.length, 10) / 10 || 1 },
          (_, index) => index + 1 // Start from 1
        )
      );
    }
  }, [filtered, items]);

  function roundUpToLimit(number, limit) {
    const remainder = number % limit;
    return remainder === 0 ? number : number + (limit - remainder);
  }

  const renderPaginationItems = () => {
    const totalPages = pages.length;
    const paginationItems = [];

    // Display only the current page on the last page
    if (page === totalPages) {
      paginationItems.push(
        <Pagination.Item key={page} active onClick={() => setPage(page)}>
          {page}
        </Pagination.Item>
      );
    } else {
      // Display "current of total" for all other pages
      paginationItems.push(
        <Pagination.Item key={page} active onClick={() => setPage(page)}>
          {page}
        </Pagination.Item>
      );
      paginationItems.push(
        <span key="of-text" className="mx-2 paginationOf">
          of
        </span>
      );
      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          active={page === totalPages}
          onClick={() => setPage(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <Pagination className="custom-pagination">
      <Pagination.Prev
        onClick={() => page > 1 && setPage(page - 1)}
        disabled={page === 1}
      >
        <img src={Prev} alt="Previous" />
      </Pagination.Prev>

      {renderPaginationItems()}

      <Pagination.Next
        onClick={() => page < pages.length && setPage(page + 1)}
        disabled={page === pages.length}
      >
        <img src={Next} alt="Next" />
      </Pagination.Next>
    </Pagination>
  );
}
