export const simplifyTradingVolume = (value) => {
  console.log(value, "incoming Value");

  // Ensure the value is a number
  value = Number(value);

  if (value === 0) {
    return "0.00";
  } else if (value >= 1000000) {
    const millionValue = (value / 1000000).toFixed(2);
    return millionValue.replace(/\.?0*$/, "") + "M";
  } else if (value >= 1000) {
    const thousandValue = (value / 1000).toFixed(2);
    return thousandValue.replace(/\.?0*$/, "") + "K";
  } else {
    return value.toFixed(2);
  }
};

export const formatTime = (timestamp) => {
  if (!timestamp) return ''; // Handle cases where the timestamp is undefined or null
  const date = new Date(timestamp);

  // Use toLocaleTimeString to get the formatted time in 12-hour format
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
}


export const capitalizeFirstLetter = (str) => {
  if (!str) return ''; // Handle cases where the input is undefined, null, or empty
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
