import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import Launch from "../../../assets/images/2.gif";
import DailyAvg from "../../../assets/images/1.gif";
import { toastify } from "../../../helpers/toast/toastify";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { setLoading } from "../../../redux/loading/loadingSlice";
// import { useNavigate } from "react-router-dom";
// import { updateUser } from "../../../redux/users/usersSlice";
import { simplifyTradingVolume } from "../../../helpers/helper";

export default function Trading({ member, setTab }) {
    const [stats, setStats] = useState({});
    const api = useApi();
    const dispatch = useDispatch();
    const user = useSelector((value) => value?.user?.user);
    const [date, setDate] = useState("");
    const [show, setShow] = useState(false);
    const community = useSelector(
        (state) => state?.user?.user?.leaderCommunity
    );

    const getUserStats = async () => {
        const response = await api("get", "stats/index");
        if (response?.status) {
            setStats(response?.data);
        } else {
            toastify(response?.message);
        }
    };

    useEffect(() => {
        getUserStats();
    }, []);

    const formatValueWithPlus = (value) => {
        if (value === null) {
            return "0";
        }

        const numericValue = parseFloat(value);
        if (isNaN(numericValue)) {
            return "0";
        }

        return numericValue >= 0
            ? `${numericValue}`
            : numericValue;
    };

    function stringToDateFormat(inputString) {
        if (!inputString) {
            return null;
        }
        const day = parseInt(inputString.substring(0, 2), 10);
        const month = parseInt(inputString.substring(2, 4), 10) - 1;
        const year = parseInt(inputString.substring(4, 6), 10) + 2000;
        const dateObject = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
        return dateObject;
    }

    function dateFormatToString(dateObject) {
        if (!dateObject) {
            return null;
        }
        const day = ("0" + dateObject.getUTCDate()).slice(-2);
        const month = ("0" + (dateObject.getUTCMonth() + 1)).slice(-2);
        const year = dateObject.getUTCFullYear().toString().substring(2, 4);
        const dateString = day + month + year;
        return dateString;
    }

    function isDateInRange(pastDate, currentDateToCheck) {
        const midnightPastDate = new Date(pastDate);
        midnightPastDate.setHours(0, 0, 0, 0);

        const midnightCurrentDateToCheck = new Date(currentDateToCheck);
        midnightCurrentDateToCheck.setHours(0, 0, 0, 0);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return (
            midnightPastDate <= midnightCurrentDateToCheck &&
            midnightCurrentDateToCheck <= currentDate
        );
    }

    useEffect(() => {
        if (community?.statsFrom) {
            setDate(dateFormatToString(new Date(community?.statsFrom)));
        }
    }, [community]);

    useEffect(() => {
        if (
            date?.length === 6 &&
            date !== dateFormatToString(new Date(community?.statsFrom)) &&
            isDateInRange(
                new Date(community?.createdAt),
                stringToDateFormat(date)
            )
        ) {
            setShow(true);
        }
    }, [date]);

    const handleStatsDate = async () => {
        try {
            dispatch(setLoading(true));
            setShow(false);
            const response = await api("post", "community/update", {
                statsFrom: new Date(stringToDateFormat(date)),
                id: community?._id,
            });
            if (response?.status) {
                await getUserStats();
                toastify("Date updated successfully.", "success");
                dispatch(setLoading(false));
            } else {
                dispatch(setLoading(false));
                toastify(response?.message || "Unable to change date.");
            }
        } catch (error) {
            console.log({ error });
        }
    };

    // const handleCheckbox = async () => {
    //     dispatch(setLoading(true));
    //     const response = await api("post", "community/update", {
    //         id: user?.leaderCommunity?._id,
    //         receiveEmailReport: !user?.leaderCommunity?.receiveEmailReport,
    //     });
    //     console.log({ response });
    //     if (response?.status) {
    //         toastify("Email preference set successfully!", "success");
    //         dispatch(updateUser(response?.data));
    //     } else {
    //         toastify(response?.message);
    //     }
    //     dispatch(setLoading(false));
    // };

    // const calculateBalance = () => {
    //     let totalBalance =
    //         parseInt(stats?.bybitBalance) + parseInt(stats?.binanceBalance);
    //     let weeklyBalance = stats?.balanceSinceLastWeek;

    //     let total = (weeklyBalance / totalBalance) * 100;

    //     return `${(total || 0)}%`;
    // };

    const calculatePotentialAccount = () => {
        let totalBalance =
            (stats?.bybitBalance || 0) + (stats?.binanceBalance || 0);

        let addition = parseInt((stats?.dailyAvgProfit || 0) * 365);
        let total = totalBalance + addition;

        let value = (total || 0);

        console.log(value, "thisvalue")

        if (value === 0) {
            return "0.00";
        } else if (value >= 1000000) {
            const millionValue = (value / 1000000).toString();
            return `$${millionValue.replace(/\.?0*$/, "") + "M"}`;
        } else if (value >= 1000) {
            const thousandValue = (value / 1000).toString();
            return `$${thousandValue.replace(/\.?0*$/, "") + "K"}`;
        } else {
            return `$${value}`;
        }
    };

    return (
        <div className="graph_info">
            {show && (
                <ConfirmationModal
                    yesAction={handleStatsDate}
                    noAction={() => setShow(false)}
                    text="Are you sure you want to change date?"
                />
            )}

            <div className="stats_cards pb-5">
                <div className="statusTrading mx-0">
                    <p>
                        Status:{" "}
                        {user?.binanceCredentials?.isConnected ||
                            user?.bybitCredentials?.isConnected
                            ? "Trading"
                            : "Not Trading"}
                    </p>
                    <p>
                        {user?.binanceCredentials?.isConnected
                            ? "Connected to: Binance"
                            : user?.bybitCredentials?.isConnected
                                ? "Connected to: ByBit"
                                : user?.binanceCredentials?.isConnected &&
                                    user?.bybitCredentials?.isConnected
                                    ? "Connected to: Binance, ByBit"
                                    : "Not Connected"}
                    </p>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="white_card">
                        <div className="h-100 d-flex flex-column justify-content-center">
                            <p className="text-truncate color-black bold fs19">
                                Your Trading Account
                            </p>
                            <h4 className="text-truncate color-black bold fs25">
                                {"$"}
                                {simplifyTradingVolume(
                                    (stats?.binanceBalance || 0) +
                                    (stats?.bybitBalance || 0)
                                )}
                            </h4>
                            <p className="text-truncate color-black">
                                <span className="cyan-color plus fs15">
                                    {formatValueWithPlus(
                                        stats?.balanceSinceLastWeek || 0
                                    )}
                                    {"%"}
                                </span>{" "}
                                since last week ($
                                {(stats?.balanceSinceLastWeek || 0).toFixed(2)})
                            </p>
                        </div>
                    </div>
                    <div className="black_card">
                        <div className="d-flex h-100 justify-content-between align-items-center">
                            <div>
                                <p className="text-truncate bold fs19">
                                    Daily Avg. Profit
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {"$"}
                                    {simplifyTradingVolume(stats?.dailyAvgProfit || 0)}
                                </h4>
                                <p className="text-truncate">
                                    <span className="plus cyan-color fs15">
                                        {(
                                            stats?.averageProfitSinceLastWeek || 0
                                        ).toFixed(2)}
                                        {"%"}
                                    </span>{" "}
                                    since last week
                                </p>
                            </div>
                            <img src={DailyAvg} width="100px" alt="DailyAvg" />
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl">
                    <div className="mbl-100">
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Winrate
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(stats?.winRate || 0)}
                                    {"%"}
                                </h4>
                            </div>
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Avg. trade
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(stats?.avgTrade || 0)}%
                                </h4>
                            </div>
                        </div>
                        <div className="black_card position-relative">
                            <div className="d-flex h-100 justify-content-center flex-column">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <p className="community_heading text-truncate bold fs19">
                                            Performance YTD
                                        </p>
                                        <h4 className="text-truncate bold fs25">
                                            {(
                                                stats?.yearlyGrowth?.performance || 0
                                            ).toFixed(2)}
                                            %
                                        </h4>
                                        <p className="text-truncate">
                                            <span className="plus cyan-color fs15">
                                                {" "}
                                                {(
                                                    stats?.thisWeekPnlPercentage ||
                                                    0
                                                ).toFixed(2)}
                                                {"%"}
                                            </span>{" "}
                                            since last week
                                        </p>
                                    </div>
                                    <img
                                        src={Launch}
                                        className="launch"
                                        width="60px"
                                        alt="launch"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mbl-100">
                        <div className="black_card">
                            <div className="h-100 d-flex flex-column justify-content-center">
                                <p className="bold fs19">
                                    Potential trading account <br /> next year
                                </p>
                                <p className="text-truncate pb-1">
                                    (If avg. performance stays the same)
                                </p>
                                <h4 className="text-truncate cyan-color bold fs25">
                                    {/* {calculatePotentialAccount()} */}
                                    ${simplifyTradingVolume(stats?.potentialTradingAccount || 0)}
                                </h4>
                            </div>
                        </div>
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Last trade
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {(stats?.lastTrade || 0).toFixed(2)}%
                                </h4>
                            </div>
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Total trades
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {parseInt(stats?.totalTrades || 0)}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card_flex card_flex_mbl">
                    {/* <div className="mbl-100">
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Risk % per trade
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {user?.binanceCredentials
                                        ?.tradePercentage ||
                                        user?.bybitCredentials?.tradePercentage}
                                    %
                                </h4>
                            </div>
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Open trades
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {stats?.openOrders || 0}
                                </h4>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="className1  mbl-100 mx-3">
                        <div className="card_flex card_flex_mbl">
                            <div className="smallBlack_card2">
                                <p className="text-truncate bold fs15">
                                    Open trades
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {stats?.openOrders || 0}
                                </h4>
                            </div>
                            <div className="smallBlack_card2">
                                <p className="text-truncate bold fs15">
                                    Trading vol. (group)
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {"$"}
                                    {(stats?.tradeAmount || 0)}
                                </h4>
                            </div>
                            <div className="smallBlack_card2">
                                <p className="text-truncate bold fs15">
                                    Member for
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {stats?.membersMonthAvg || 0} months
                                </h4>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className=" className2 mbl-100">
                        <div className="card_flex flex-no">
                            <div className="smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Open trades
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {stats?.openOrders || 0}
                                </h4>
                            </div>
                            <div className=" smallBlack_card">
                                <p className="text-truncate bold fs15">
                                    Trading vol. (group)
                                </p>
                                <h4 className="text-truncate bold fs25">
                                    {"$"}
                                    {(stats?.tradeAmount || 0)}
                                </h4>
                            </div>

                        </div>
                        <div className="smallBlack_card3">
                            <p className="text-truncate bold fs15">
                                Member for
                            </p>
                            <h4 className="text-truncate bold fs25">
                                {stats?.membersMonthAvg || 0} months
                            </h4>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
    );
}
