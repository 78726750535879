import React, { useState, useEffect, useLayoutEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Trading from "./Trading";
import Business from "./Business";
import TradeHistory from "./TradeHistory";
import AutoShare from "./AutoShare";
import Reports from "./Reports";
import CreatePromotion from "./CreatePromotion";
import PayoutHistory from "./PayoutHistory";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/loading/loadingSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { updateUser } from "../../../redux/users/usersSlice";

function Index() {
    // const [currentTab, setCurrentTab] = useState("trading");
    const { currentTab } = useSelector(state => state.links)
    const { state } = useLocation();
    const api = useApi();
    const dispatch = useDispatch();
    useLayoutEffect(() => {
        if (state) {
            dispatch(setCurrentTab(state?.tab));
            window.scrollTo({
                top: 0,
                behavior: "smooth", // Optional: Adds smooth scrolling animation
            });
        }
    }, [state]);

    const handleTabSelect = (selectedTab) => {
        dispatch(setCurrentTab(selectedTab));
    };

    useEffect(() => {
        console.log({ currentTab })
    }, [currentTab]);

    useEffect(() => {
        if (!["createPromotion", "autoShare", "TradeHistory", "reports", "trading", "business", "reports", "payment", "payoutHistory"].includes(currentTab)) {
            dispatch(setCurrentTab("business"))
        }

    }, []);

    return (
        <div className="container">
            <div className="row justify-content-center support_wrapper h-100">
                <div className="">
                    <div className={`${currentTab == "TradeHistory" ? "" : "mx-691"} mx-auto`}>
                        <Tabs
                            defaultActiveKey="trading"
                            id="uncontrolled-tab-example"
                            className="copyTradeTab d-none"
                            activeKey={currentTab}
                            onSelect={handleTabSelect}
                        >
                            <Tab eventKey="trading"
                            // title="Trading"
                            >
                                <Trading setTab={handleTabSelect} />
                            </Tab>
                            <Tab eventKey="reports"
                            // title="Reports"
                            >
                                <Reports setTab={handleTabSelect} />
                            </Tab>
                            <Tab eventKey="business"
                            // title="Business"
                            >
                                <Business setTab={handleTabSelect} />
                            </Tab>
                            <Tab eventKey="TradeHistory"
                            // title="Trade history"
                            >
                                <TradeHistory setTab={handleTabSelect} />
                            </Tab>
                            <Tab eventKey="autoShare"
                            // title="Auto share"
                            >
                                <AutoShare setTab={handleTabSelect} />
                            </Tab>
                            <Tab
                                eventKey="createPromotion"
                            // title="Create promotion"
                            >
                                <CreatePromotion setTab={handleTabSelect} />
                            </Tab>
                            <Tab
                                eventKey="payoutHistory"
                            // title="Create promotion"
                            >
                                <PayoutHistory />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
