import React, { useEffect, useRef, useState } from "react";
import edit_icon from "../../../assets/images/Pen.svg";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
// import { api } from "../../../helpers/apiHelper/requestHelper";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loading/loadingSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import open_icon from "../../../assets/images/View.svg";
import close_icon from "../../../assets/images/noView.svg";
import Button from "../../../components/button/Button";
import SettingsSelect from "./SettingsSelect";

export default function MemberAccountSettings() {
    const api = useApi();
    const [data, setData] = useState({
        email: "",
        password: "",
        passwordEdit: false,
    });
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const emailRef = useRef(null);
    const [security, setSecurity] = useState({});
    const passwordRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [editable, setEditable] = useState({
        email: true,
        password: true
    })
    const user = useSelector((state) => state?.user?.user);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const fetchPassword = async () => {
        const response = await api("get", "users/viewPassword");
        // setData({ email: user?.email, password: response?.data });
        setData(prevData => ({
            ...prevData,
            password: response.data
        }));
    };

    useEffect(() => {
        if (user) {
            setData({
                ...data,
                email: user?.email,
            });
            fetchPassword();
        }
    }, [user]);

    useEffect(() => {
        setSecurity(user?.security);
    }, [user]);

    const save = async (changeOtpSettings) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const uppercaseRegex = /[A-Z]/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

        if (!emailRegex.test(data?.email)) {
            hanldeEditableInput(emailRef);
            toastify("Please enter a valid email address");
            dispatch(setLoading(false));
            setShow(false);
            return;
        }

        if (!data?.password || data.password.length < 8 ||
            !uppercaseRegex.test(data.password) ||
            !specialCharRegex.test(data.password)) {
            let errorMessage = !data?.password
                ? "Please enter a password"
                : data.password.length < 8
                    ? "Password must be at least 8 characters long"
                    : !uppercaseRegex.test(data.password)
                        ? "Password must contain at least one uppercase letter"
                        : "Password must contain at least one special character";

            toastify(errorMessage);
            dispatch(setLoading(false));
            setShow(false);
            return;
        }

        setEditable({
            email: true,
            password: true
        })
        setShow(false);
        dispatch(setLoading(true));
        let changes = {};

        if (user?.email !== data?.email)
            changes = { ...changes, email: data?.email };
        if (data?.password) changes = { ...changes, password: data?.password };
        if (security)
            changes = {
                ...changes,
                security: {
                    ...security,
                    otp: changeOtpSettings ? security.otp : true,
                },

            };
        console.log(changeOtpSettings, "changePassword")

        if (data?.email || data?.password || security) {
            const response = await api("post", "users/updateAccountSettings", {
                ...changes,
            });
            if (response?.status) {
                toastify(
                    "Account Information updated successfully.",
                    "success"
                );
                if (changes?.email) {
                    localStorage.setItem(
                        "lastVisitedRoute",
                        window.location.pathname
                    );
                    localStorage.removeItem("token");
                    dispatch(
                        storeUser({
                            user: null,
                            token: null,
                        })
                    );
                }
                if (changes?.password) {
                    console.log(changes?.password, "password")
                    setData({ ...data, passwordEdit: true });
                    console.log(data, "dataSet")
                }
                localStorage.setItem("token", response?.data?.token);
                dispatch(storeUser(response?.data));
            } else {
                toastify(response?.message);
            }
        }
        dispatch(setLoading(false));
    };


    const hanldeEditableInput = (inputValue) => {
        console.log(inputValue, "valueeeeeee")
        let userAgent = navigator?.userAgent;
        let tempInput = null
        if (!userAgent?.includes("Firefox")) {
            // console.log("fireBox-Browser");
            tempInput = document.createElement('input');
            document.body.appendChild(tempInput);
            tempInput.className = "hidden-input"
            tempInput.focus();
        }

        setTimeout(() => {
            if (tempInput) {
                tempInput?.remove();
            }
            if (inputValue.current) {
                inputValue?.current?.focus();
            }
        }, 0);
    }

    return (
        <div className="container mt-sm-30">
            <div className="row justify-content-center h-100 support_wrapper">
                {show && (
                    <ConfirmationModal
                        text="If you change your email, you will need to login again to verify email address."
                        yesAction={() => save(true)}
                        noAction={() => setShow(false)}
                    />
                )}
                <div id="accordionExample" className="copyTrade pb-sm-50px">
                    <span className="topLines"></span>
                    <div className="connection mt-30">
                        <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
                            <h4 className="fs20 text_GB mb-51">
                                Your settings
                            </h4>
                        </div>
                        <div className="h-100 d-flex flex-column position-relative">
                            <div className="mx-562">
                                <div className="row">
                                    <div className=" pb-4 mt-auto">
                                        <div className="customInput">
                                            <label
                                                onClick={() => {

                                                    setEditable((prevEditable) => ({
                                                        ...prevEditable,
                                                        email: !prevEditable.email
                                                    }))
                                                    hanldeEditableInput(emailRef)
                                                    //settimeout
                                                    // setTimeout(() => {
                                                    //     emailRef.current.focus();
                                                    // }, 0);
                                                }}
                                                className="cursor-pointer editIcon"
                                            >
                                                <img
                                                    src={edit_icon}
                                                    alt="img"
                                                />
                                            </label>
                                            <div className="form-floating">
                                                <input
                                                    ref={emailRef}
                                                    type="email"
                                                    id="emailReff"
                                                    className="form-control fs15"
                                                    placeholder="Email Address"
                                                    value={data?.email}
                                                    disabled={editable?.email}
                                                    onChange={(e) =>
                                                        setData({
                                                            ...data,
                                                            email: e.target
                                                                .value,
                                                        })
                                                    }
                                                />
                                                <label for="emailReff">
                                                    Email
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" pb-4">
                                        <div className="customInput">
                                            <label
                                                onClick={() => {
                                                    setData({
                                                        ...data,
                                                        passwordEdit:
                                                            !data.passwordEdit,
                                                    });
                                                    setEditable({
                                                        ...editable,
                                                        password: !editable.password
                                                    })
                                                    hanldeEditableInput(passwordRef)
                                                    // setTimeout(() => {
                                                    //     passwordRef.current.focus();
                                                    // }, 0);
                                                }}
                                                className="cursor-pointer editIcon"
                                            >
                                                <img
                                                    src={edit_icon}
                                                    alt="img"
                                                />
                                            </label>
                                            <div className="form-floating">
                                                <input
                                                    ref={passwordRef}
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    id="password"
                                                    className="form-control fs15"
                                                    placeholder={
                                                        data?.passwordEdit
                                                            ? "Enter New Password"
                                                            : "**********"
                                                    }
                                                    disabled={editable?.password}
                                                    value={data?.password}
                                                    onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            password:
                                                                e.target.value,
                                                        })
                                                        console.log(e.target.value, "valueeee")
                                                        console.log(data?.password, "valueeee")
                                                    }
                                                    }
                                                />
                                                <label for="password">
                                                    Password
                                                </label>
                                            </div>
                                            <div
                                                className="view-icon"
                                                onClick={() => {
                                                    togglePasswordVisibility();
                                                    // fetchPassword();
                                                }}
                                            >
                                                {showPassword ? (
                                                    <img
                                                        src={open_icon}
                                                        alt="close Icon"
                                                    />
                                                ) : (
                                                    <img
                                                        src={close_icon}
                                                        alt="open Icon"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="setting-page-mt-80">
                                    <div className="securitytitle">
                                        <h3>Security on your account</h3>
                                    </div>
                                </div>
                                <div className=" pb-4">
                                    <div className="settingDropdown">
                                        <p className="descriptive">
                                            Use One Time Password password on
                                            log-in?
                                        </p>
                                        <SettingsSelect
                                            title="Use One Time Password password on log-in?"
                                            data={security?.otp}
                                            setData={(value) =>
                                                setSecurity({
                                                    logout: security?.logout,
                                                    otp: value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className=" pb-4">
                                    <div className="settingDropdown">
                                        <p className="descriptive">
                                            Log out after 15 min. of inactivity?
                                        </p>
                                        <SettingsSelect
                                            title="Log out after 15 min. of inactivity?"
                                            data={security?.logout}
                                            setData={(value) =>
                                                setSecurity({
                                                    otp: security?.otp,
                                                    logout: value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <div className="d-flex justify-content-end">
                                        <button
                                            className="cyan-btn promoteBtn medium"
                                            onClick={() => {
                                                if (
                                                    user?.email !== data?.email
                                                ) {
                                                    setShow(true);
                                                } else {
                                                    save(true);
                                                }
                                            }}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
