import React, { useState, useRef, useEffect } from "react";
import edit_icon from "../../../assets/images/Pen.svg";
import Plus from "../../../assets/images/addIcon.svg";
import Promoteapp from "../../../assets/images/Promoteapp.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";

function PromoteApp() {
    const [isDisabled, setIsDisabled] = useState({
        ios: true,
        android: true,
        other: true,
    });
    const iosRef = useRef(null);
    const androidRef = useRef(null);
    const otherRef = useRef(null);
    const community = useSelector((state) => state.user.user?.leaderCommunity);
    const [links, setLinks] = useState();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useApi();

    useEffect(() => {
        setLinks(community?.appLinks || null);
    }, [community]);

    const handleEditClick = (ref) => {
        setIsDisabled((prevIsDisabled) => ({
            ...prevIsDisabled,
            [ref.current.name]: !prevIsDisabled[ref.current.name],
        }));

        // Use setTimeout to ensure that the input is focused before selecting text
        let userAgent = navigator?.userAgent;
        let tempInput = null
        if (!userAgent?.includes("Firefox")) {
            // console.log("fireBox-Browser");
            tempInput = document.createElement('input');
            document.body.appendChild(tempInput);
            tempInput.className = "hidden-input"
            tempInput.focus();
        }

        setTimeout(() => {
            if (tempInput) {
                tempInput?.remove();
            }
            if (ref.current) {
                ref.current.focus();
                // const end = ref.current.value.length;
                // ref.current.setSelectionRange(end, end);
            }
        }, 0);
        // setTimeout(() => {
        //     ref.current.focus();
        // }, 0);
    };

    const logOut = () => {
        // localStorage.removeItem("token");
        // localStorage.removeItem("lastVisitedRoute");
        localStorage.clear()
        dispatch(
            storeUser({
                user: null,
                token: null,
            })
        );

        navigate("/login");
    };

    function validURL(str) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i' // fragment locator
        );
        return !!pattern.test(str);
    }

    const handleLinksSave = async () => {
        let token = localStorage.getItem("token");
        console.log(links, "linkssssss");

        if (!token) {
            toastify("Your token has expired, please login again.");
            logOut();
            return;
        }

        if (!links) {
            console.log("powerrrttttttttttt")
        }
        // Check if all links are empty
        if (links == {} || links == null) {
            toastify("Please add a valid link")
            return
        }
        // if (links?.ios == "" || links?.android == "" || links?.other == "") {
        //     toastify("Please add a valid iOS link");
        //     return;
        // }
        // if (links?.ios == "" || links?.android == "" || links?.other == "") {
        //     toastify("Please add a valid iOS link");
        //     return;
        // }

        // Validate each link
        if (!links?.ios || !validURL(links.ios)) {
            toastify("Please add a valid iOS link");
            return;
        }
        if (!links?.android || !validURL(links.android)) {
            toastify("Please add a valid android link");
            return;
        }
        console.log(links.other, "aaaaa", !validURL(links.other), "othereee")
        if (links?.other == null) {
            console.log("othercheckkkk")
        }
        else if (!validURL(links.other)) {
            console.log("asdadsadas")
            toastify("Please add a valid other link");
            return;
        }

        // Only run if at least one link is provided
        dispatch(setLoading(true));
        const response = await api("post", "community/update", {
            appLinks: links,
            id: community?._id,
        });
        console.log({ response });

        if (response?.status) {
            dispatch(updateUser(response?.data));
            setIsDisabled({
                ios: true,
                android: true,
                other: true,
            });
            toastify("App links added successfully.", "success");
        } else {
            toastify(response?.message || "Unable to add app links.");
        }

        dispatch(setLoading(false));
    };


    return (
        <React.Fragment>
            <div className="connection">
                <div className="mx-562">
                    <div className="title">
                        <h3>Promote your app on your users dashboard</h3>
                        <p>
                            To promote it on your subscribers dashboard when
                            logged in you just need to submit the App store
                            URL’s here. Easy.
                        </p>
                    </div>
                    <div className="customInput mt-41 mb-0">
                        <label
                            onClick={() => handleEditClick(iosRef)}
                            className="cursor-pointer editIcon"
                        >
                            <img src={edit_icon} alt="img" />
                        </label>
                        <div className="form-floating">
                            <input
                                className="form-control fs18"
                                id="nameReff"
                                ref={iosRef}
                                type="text"
                                name="ios"
                                disabled={isDisabled?.ios}
                                placeholder=""
                                value={links?.ios}
                                minLength={1}
                                onChange={(e) =>
                                    setLinks({
                                        ...links,
                                        ios: e.target.value,
                                    })
                                }
                            />
                            <label for="nameReff">Link to iOS App Store</label>
                        </div>
                    </div>

                    <div className="customInput  mt-28">
                        <label
                            onClick={() => handleEditClick(androidRef)}
                            className="cursor-pointer editIcon"
                        >
                            <img src={edit_icon} alt="img" />
                        </label>
                        <div className="form-floating">
                            <input
                                id="linkReff"
                                ref={androidRef}
                                name="android"
                                disabled={isDisabled?.android}
                                type="text"
                                className="form-control fs18"
                                placeholder=""
                                value={links?.android}
                                onChange={(e) =>
                                    setLinks({
                                        ...links,
                                        android: e.target.value,
                                    })
                                }
                            />
                            <label for="linkReff">
                                Link to Android Play Store
                            </label>
                        </div>
                    </div>

                    {(links?.other || links?.other === "") && (
                        <div className="customInput  mt-28">
                            <label
                                onClick={() => handleEditClick(otherRef)}
                                className="cursor-pointer editIcon"
                            >
                                <img src={edit_icon} alt="img" />
                            </label>
                            <div className="form-floating">
                                <input
                                    id="linkReff"
                                    ref={otherRef}
                                    name="other"
                                    disabled={isDisabled?.other}
                                    type="text"
                                    className="form-control fs18"
                                    placeholder=""
                                    value={links?.other}
                                    onChange={(e) =>
                                        setLinks({
                                            ...links,
                                            other: e.target.value,
                                        })
                                    }
                                />
                                <label for="linkReff">
                                    Link to any other App Store
                                </label>
                            </div>
                        </div>
                    )}
                    <div className="d-flex justify-content-between align-items-center mt-36">
                        <div className="d-flex align-items-center w-50">
                            {!(links?.other || links?.other === "") && (
                                <>
                                    <img
                                        src={Plus}
                                        alt="plus"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setLinks({ ...links, other: "" });
                                            setIsDisabled({
                                                ...isDisabled,
                                                other: true,
                                            });
                                        }}
                                    />
                                    <p className="ms-2 light-gray-color">
                                        Add extra link
                                    </p>
                                </>
                            )}
                        </div>
                        <button
                            className="black-btn promoteBtn text_GM"
                            onClick={handleLinksSave}
                        >
                            Save
                        </button>
                    </div>
                    <div className="imgBlock">
                        <h4>How does it look like?</h4>
                        <img width="100%" src={Promoteapp} alt="Promoteapp" />
                    </div>
                </div>
                <div className="articleRelative">
                    <RenderLinks page="appPromote" />
                </div>
            </div>
        </React.Fragment>
    );
}

export default PromoteApp;
