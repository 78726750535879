import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ExchangeAccounts from "./ExchangeAccounts";

import { Link } from "react-router-dom";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { setEditable, updateUser } from "../../../redux/users/usersSlice";
import RenderLinks from "../../../components/links/RenderLinks";
import editIcon from "../../../assets/images/Pen.svg"

function OnboardingUser({ setTab }) {
    const api = useApi();
    const [community, setCommunity] = useState({
        onboardingUser: {
            minFund: "",
            portfolioPercentage: "5",
            selectedExchange: [],
        },
    });
    const user = useSelector((value) => value?.user?.user);
    const editable = useSelector((value) => value?.user?.editable);
    // const [editable, setEditable] = useState(true)
    const numberRegex = new RegExp(`^[0-9]*$`);
    const dispatch = useDispatch();

    let initialValue =
        // user?.leaderCommunity?.onboardingUser?.selectedExchange?.map(
        //     (exchange) => exchange
        // );
        // user?.leaderCommunity?.onboardingUser?.selectedExchange;
        user?.bybitCredentials?.isConnected
            ? ["Bybit Derivatives USDT Perpetual account"]
            : user?.binanceCredentials?.isConnected
                ? ["Binance Futures USDT Perpetual account"]
                : [""]

    useEffect(() => {
        if (user?.leaderCommunity) {
            setCommunity({
                ...user?.leaderCommunity,
                onboardingUser: {
                    ...user?.leaderCommunity?.onboardingUser,
                    portfolioPercentage: user?.leaderCommunity?.onboardingUser
                        ?.portfolioPercentage
                        ? user?.leaderCommunity?.onboardingUser
                            ?.portfolioPercentage + "%"
                        : "",
                },
            });
            console.log(community, "useEffectCpmmunity")
        }
    }, [user]);


    useEffect(() => {
        user?.bybitCredentials?.isConnected
            ? handleExchangeChange([{ value: "Bybit Derivatives USDT Perpetual account" }])
            : user?.binanceCredentials?.isConnected
                ? handleExchangeChange([{ value: "Binance Futures USDT Perpetual account" }])
                : handleExchangeChange([])
    }, [])

    const handleInputChange = (value, key) => {
        setCommunity((prevCommunity) => {
            const newValues = { ...prevCommunity.onboardingUser };

            if (key === "minFund") newValues.minFund = value;
            else if (key === "portfolioPercentage") {
                newValues.portfolioPercentage = value;
            }
            console.log(community, "InputHanldeCommunity")

            return { ...prevCommunity, onboardingUser: newValues };
        });
    };

    const handleExchangeChange = (selectedExchange) => {
        setCommunity((prevCommunity) => {
            const newonboardingUser = {
                ...prevCommunity.onboardingUser,
                // selectedExchange: selectedExchange.map(
                //     (exchange) => exchange.value
                // ),
                // Assuming the selectedExchange is an object with a 'value' property
                selectedExchange: selectedExchange[0]?.value
            };

            return {
                ...prevCommunity,
                onboardingUser: newonboardingUser,
            };
        });
    };

    const submit = async () => {
        dispatch(setEditable(true))
        dispatch(setLoading(true));

        // Validate input fields
        if (
            !community?.onboardingUser?.minFund ||
            !community?.onboardingUser?.selectedExchange
        ) {
            toastify("Minimum funding is required.", "error");
            dispatch(setLoading(false));
            return;
        }

        let { minFund, portfolioPercentage, selectedExchange } =
            community.onboardingUser;

        minFund = minFund?.replace(/^0+/, "") || "0";

        const response = await api("post", "users/onboardingUsers", {
            minFund,
            portfolioPercentage: portfolioPercentage.replace("%", ""),
            selectedExchange,
        });
        console.log(response, "==myResponseee")

        if (response?.status) {
            // without Uncomment it show my prevoius fund value.. i comment this but i check both frontend and backend value is correct..
            // dispatch(updateUser(response?.data));
            console.log(response, "dataaa")
            // setCommunity((prevCommunity) => ({
            //     ...prevCommunity,
            //     onboardingUser: {
            //         ...prevCommunity.onboardingUser,
            //         minFund: prevCommunity.onboardingUser.minFund // This line keeps the existing minFund value; adjust as needed
            //     }
            // }));
            dispatch(updateUser({ ...user, leaderCommunity: response?.data }));
            console.log(community, "communityOnSubmit")
            if (user?.firstVisit) {
                toastify("Onboarding completed successfully.", "success");
                setTab("url");
            } else {
                toastify("Onboarding updated successfully.", "success");
            }
        } else {
            toastify(response?.message);
            console.log(response?.message);
        }
        dispatch(setLoading(false));
    };


    const inputRef = useRef(null);

    // useEffect(() => {
    //     const input = inputRef.current;
    //     if (input) {
    //         input.focus();

    //         // Ensure the keyboard opens on mobile devices
    //         const touchStartHandler = () => {
    //             input.focus();
    //             input.removeEventListener('touchstart', touchStartHandler);
    //         };
    //         input.addEventListener('touchstart', touchStartHandler);
    //     }
    // }, []);

    const handleSubcriptionClick = () => {
        if (inputRef.current) {
            inputRef?.current?.focus();  // Focus the input field when the card is clicked
        }
    };

    const handleEdit = () => {
        dispatch(setEditable(false))
        // Use setTimeout to ensure that the input is focused before selecting text
        let userAgent = navigator?.userAgent;
        let tempInput = null
        if (!userAgent?.includes("Firefox")) {
            // console.log("fireBox-Browser");
            tempInput = document.createElement('input');
            document.body.appendChild(tempInput);
            tempInput.className = "hidden-input"
            tempInput?.focus();
        }

        setTimeout(() => {
            if (tempInput) {
                tempInput?.remove();
            }
            if (inputRef?.current) {
                inputRef?.current?.focus();
                // const end = inputRef.current.value.length;
                // inputRef.current.setSelectionRange(end, end);
            }
        }, 0);
        console.log(editable, "edibatle")
    };

    return (
        <div className="onboardingUser">
            <div className="title">
                <h3 className="text_GB fs20">Help onboard your subscribers</h3>
                <p>
                    We need the following information to make it as smooth as possible for your subscribers to join your copy trading.
                </p>
            </div>
            <div className="tier mx-691 mt-51" >
                <h3>Minimum funding on trading account</h3>
                <div className="card" onClick={handleSubcriptionClick}>
                    <div className="block blockedit-hel-onborad">
                        <div className="form-floating">
                            <input
                                ref={inputRef}
                                autoComplete="new-password"
                                className="form-control"
                                id="onboardingUser"
                                type="text"
                                disabled={user?.firstVisit ? false : editable}
                                placeholder="$"
                                value={
                                    community?.onboardingUser?.minFund !==
                                        undefined
                                        ? community?.onboardingUser?.minFund ===
                                            ""
                                            ? ""
                                            : "$" +
                                            community?.onboardingUser?.minFund
                                        : ""
                                }
                                maxLength={6}
                                onChange={(val) => {
                                    const inputWithoutDollar =
                                        val.target.value.replace(/^\$/, ""); // Remove $ sign if present
                                    const isValidNumber =
                                        numberRegex.test(inputWithoutDollar) ||
                                        inputWithoutDollar === "";

                                    if (isValidNumber) {
                                        handleInputChange(
                                            inputWithoutDollar,
                                            "minFund"
                                        );
                                    }
                                }}
                            />
                            <label for="onboardingUser">
                                Your subscribers should have
                            </label>
                        </div>
                        {!user?.firstVisit &&
                            <div className="block edit-button-block">
                                <button type="button" className="edit-button-subscription mt-18-rev me-n-1 ms-auto" onClick={handleEdit}>
                                    <img className="" src={editIcon} alt="" />
                                </button>
                            </div>
                        }
                    </div>
                </div>
                <div className="d-none">
                    <h3>Your subs should add funds to</h3>
                    <ExchangeAccounts
                        onExchangeChange={handleExchangeChange}
                        initialValue={initialValue}
                    />
                </div>
                {!editable &&
                    <div className="d-flex justify-content-end mt-5">
                        <button className="btn cyan-btn fs13" onClick={submit}>
                            {user?.firstVisit ? "Next" : "Save Changes"}
                        </button>
                    </div>
                }
                {user?.firstVisit &&
                    <div className="d-flex justify-content-end mt-5">
                        <button className="btn cyan-btn fs13" onClick={submit}>
                            {user?.firstVisit ? "Next" : "Save Changes"}
                        </button>
                    </div>}
            </div>
            {/* <RenderLinks page="copyTradeOnboarding" /> */}
        </div>
    );
}

export default OnboardingUser;
