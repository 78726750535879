import React from "react";

const Button = ({ name, handleClick, newStyle, exchange, btnDisable }) => {
    return (
        <div className="d-flex align-items-center justify-content-end">
            <button
                className={
                    exchange ? newStyle : "btn btn-success radius custom-btn"
                }
                onClick={handleClick}
                disabled={btnDisable}
            >
                {name}
            </button>
        </div>
    );
};
//

export default Button;
